  // for running locally against Production Kontent.ai project
  export const environment = {
    production: false,
    region: "us-east-1",
    identityPoolId: "us-east-1:551c9616-7c13-44bb-8b06-3ad0b0b2997b",
    userPoolId: "us-east-1_UUGkGznV8",
    ncoaApiBaseUrl: "https://dev.api.ncoa.org",
    contentId: "0803a328-d20d-488e-bab9-55f8e5923ea4",
    verticalTaxonomyId: "4c4422d4-fc06-4d66-84e5-768c2652e12e",
    taxonomyElementCodename: "awp_verticals__topics__and_subtopics",
    environment: "production",
    benefitsBaseUrl: "https://au878hmsyh.execute-api.us-east-1.amazonaws.com/prod/programsBySubsetState",
    workflowStep: "draft",
    prerender: "",
    PROJECT_ID: "ffacfe7d-10b6-0083-2632-604077fd4eca",
    PRIMARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjVjM2EyZWM1ZGJhNDQ2NjlhMDg4ZmNkYjU0ZTM0MWRkIiwNCiAgImlhdCI6ICIxNTk1NDEzNzM3IiwNCiAgImV4cCI6ICIxOTQxMDEzNzM3IiwNCiAgInByb2plY3RfaWQiOiAiNzhlMmFlMzUxMDFkMDA2ZDVmYzkzZDcyNzRlYWY1ZDYiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.hxyIUTmqi4qZNCBZSmxbbkVwm5Ts4xzYw4P_4Wd4Nmw",
    SECONDARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjYwNzdhMWYxZjg5YzRlZDE4NzE0MTViNTliY2U0M2RiIiwNCiAgImlhdCI6ICIxNTk0OTEyODU1IiwNCiAgImV4cCI6ICIxOTQwNTEyODU1IiwNCiAgInByb2plY3RfaWQiOiAiZmZhY2ZlN2QxMGI2MDA4MzI2MzI2MDQwNzdmZDRlY2EiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.wYQ0buZHXtj2QdrbnjkUN9ETCLAqmhzLH4zPCUj62_M",
    API_BASE_URL: "https://deliver.kontent.ai",
    maxUserIdleTime: "60",
    maxUserIdleTimeOut: "600",
    feedbackAPIUrl: "https://api.ncoa.org/feedback"
  };


  // for running locally against Dev Kontent.ai project
  // export const environment = {
  //   production: false,
  //   region: "us-east-1",
  //   identityPoolId: "us-east-1:551c9616-7c13-44bb-8b06-3ad0b0b2997b",
  //   userPoolId: "us-east-1_UUGkGznV8",
  //   ncoaApiBaseUrl: "https://dev.api.ncoa.org",
  //   contentId: "0803a328-d20d-488e-bab9-55f8e5923ea4",
  //   verticalTaxonomyId: "4c4422d4-fc06-4d66-84e5-768c2652e12e",
  //   taxonomyElementCodename: "awp_verticals__topics__and_subtopics",
  //   environment: "production",
  //   benefitsBaseUrl: "https://au878hmsyh.execute-api.us-east-1.amazonaws.com/prod/programsBySubsetState",
  //   workflowStep: "draft",
  //   prerender: "",
  //   PROJECT_ID: "611af8a5-db0e-0087-7a1a-b3398c82c042",
  //   PRIMARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjVjM2EyZWM1ZGJhNDQ2NjlhMDg4ZmNkYjU0ZTM0MWRkIiwNCiAgImlhdCI6ICIxNTk1NDEzNzM3IiwNCiAgImV4cCI6ICIxOTQxMDEzNzM3IiwNCiAgInByb2plY3RfaWQiOiAiNzhlMmFlMzUxMDFkMDA2ZDVmYzkzZDcyNzRlYWY1ZDYiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.hxyIUTmqi4qZNCBZSmxbbkVwm5Ts4xzYw4P_4Wd4Nmw",
  //   SECONDARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjYwNzdhMWYxZjg5YzRlZDE4NzE0MTViNTliY2U0M2RiIiwNCiAgImlhdCI6ICIxNTk0OTEyODU1IiwNCiAgImV4cCI6ICIxOTQwNTEyODU1IiwNCiAgInByb2plY3RfaWQiOiAiZmZhY2ZlN2QxMGI2MDA4MzI2MzI2MDQwNzdmZDRlY2EiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.wYQ0buZHXtj2QdrbnjkUN9ETCLAqmhzLH4zPCUj62_M",
  //   API_BASE_URL: "https://deliver.kontent.ai",
  //   maxUserIdleTime: "60",
  //   maxUserIdleTimeOut: "600",
  //   feedbackAPIUrl: "https://api.ncoa.org/feedback"
  // };

 // for running locally against Production Preview Kontent.ai project
//  export const environment = {
//   production: false,
//   region: "us-east-1",
//   identityPoolId: "us-east-1:551c9616-7c13-44bb-8b06-3ad0b0b2997b",
//   userPoolId: "us-east-1_UUGkGznV8",
//   ncoaApiBaseUrl: "https://dev.api.ncoa.org",
//   contentId: "0803a328-d20d-488e-bab9-55f8e5923ea4",
//   verticalTaxonomyId: "4c4422d4-fc06-4d66-84e5-768c2652e12e",
//   taxonomyElementCodename: "awp_verticals__topics__and_subtopics",
//   environment: "production",
//   benefitsBaseUrl: "https://au878hmsyh.execute-api.us-east-1.amazonaws.com/prod/programsBySubsetState",
//   workflowStep: "draft",
//   prerender: "",
//   PROJECT_ID: "ffacfe7d-10b6-0083-2632-604077fd4eca",
//   PRIMARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImNiMzg3ZTE3YjU1MDQ2NjM4MTE2ZjNlMjlhMTQzYTExIiwNCiAgImlhdCI6ICIxNTk0OTEyODU1IiwNCiAgImV4cCI6ICIxOTQwNTEyODU1IiwNCiAgInByb2plY3RfaWQiOiAiZmZhY2ZlN2QxMGI2MDA4MzI2MzI2MDQwNzdmZDRlY2EiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.PrHl6Yb8T7PoUteQNmosYOrBz0v3wDfYAAqymTBoGuU",
//   SECONDARY_KEY: "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjYwNzdhMWYxZjg5YzRlZDE4NzE0MTViNTliY2U0M2RiIiwNCiAgImlhdCI6ICIxNTk0OTEyODU1IiwNCiAgImV4cCI6ICIxOTQwNTEyODU1IiwNCiAgInByb2plY3RfaWQiOiAiZmZhY2ZlN2QxMGI2MDA4MzI2MzI2MDQwNzdmZDRlY2EiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.wYQ0buZHXtj2QdrbnjkUN9ETCLAqmhzLH4zPCUj62_M",
//   API_BASE_URL: "https://preview-deliver.kontent.ai",
//   maxUserIdleTime: "60",
//   maxUserIdleTimeOut: "600",
//   feedbackAPIUrl: "https://api.ncoa.org/feedback"
// };