import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'ncoa-template-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class Default implements AfterViewInit {
  currentYear = new Date().getFullYear();
  private currentOpenMenuId: string | null = null;
  lastClickedButton: string | null = null;
  private globalClickListener: (() => void) | null = null;
  @ViewChild('headerInners') headerInners: ElementRef;
  @ViewChild('stickyMenu') stickyMenu: ElementRef;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngAfterViewInit() {
    const options = {
      root: null,
      threshold: 0,
      rootMargin: '0px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.renderer.addClass(this.stickyMenu.nativeElement, 'stickySiteHeaderContainer_isSticky__LdrO5');
          this.closeCurrentMenu()
        } else {
          this.renderer.removeClass(this.stickyMenu.nativeElement, 'stickySiteHeaderContainer_isSticky__LdrO5');
          this.closeCurrentMenu()
        }
      });
    }, options);

    observer.observe(this.headerInners.nativeElement);
  }


  open(menuId: string): void {
    if (this.currentOpenMenuId === menuId) {
      this.closeCurrentMenu();
      return;
    }
    if (this.currentOpenMenuId) {
      this.closeCurrentMenu();
    }

    this.currentOpenMenuId = menuId;
    this.lastClickedButton = menuId+'-button';

    const element = document.getElementById(menuId);
    const button = document.getElementById(menuId+'-button');
    if (element) {
      element.classList.add('siteNavigation_isOpen__IwoYC');
      setTimeout(() => this.addGlobalClickListener(), 0);
    }
    if (button) {
      button.classList.add('siteNavigation_isOpen__IwoYC');
    }
  }

  closeCurrentMenu(): void {
    if (this.currentOpenMenuId) {
      const currentMenu = document.getElementById(this.currentOpenMenuId);
      const currentButton = document.getElementById(this.lastClickedButton!);
      if (currentMenu) {
        currentMenu.classList.remove('siteNavigation_isOpen__IwoYC');
      }
      if (this.lastClickedButton) {
        currentButton.classList.remove('siteNavigation_isOpen__IwoYC');
      }
    }
    this.removeGlobalClickListener();
    this.lastClickedButton = null;
    this.currentOpenMenuId = null;
  }


  private addGlobalClickListener(): void {
    this.globalClickListener = this.renderer.listen('document', 'click', (event: Event) => {
      const targetElement = event.target as HTMLElement;
      const menuElement = document.getElementById(this.currentOpenMenuId!);

      if (menuElement && !menuElement.contains(targetElement)) {
        this.closeCurrentMenu();
      }
    });
  }

  private removeGlobalClickListener(): void {
    if (this.globalClickListener) {
      this.globalClickListener();
      this.globalClickListener = null;
    }
  }

  showMenu(){
    const element = document.getElementById('mobile-menu-container');

    if (element) {
      element.classList.add('mobileMenuContainer_isOpen__WBf2z');
    }
  }

  hideMenu(){
    const element = document.getElementById('mobile-menu-container');

    if (element) {
      element.classList.remove('mobileMenuContainer_isOpen__WBf2z');
    }
  }

  openMobileMenu(menuId: string): void {
    const element = document.getElementById(menuId);

    if (element) {
      element.classList.toggle('is-open');
    }
  }

  openSearch(){
    const element = document.getElementById('search-overlay');

    if (element) {
      element.classList.add('searchUtilities_searchOverlayOpen__8JMdC');
    }
  }
  closeSearch(){
    const element = document.getElementById('search-overlay');

    if (element) {
      element.classList.remove('searchUtilities_searchOverlayOpen__8JMdC');
    }
  }

  onSubmit(form: any) {
    if (form.valid) {
      const query = form.value.search;
      window.location.href = `search?q=${query}`;
    }
  }
}
