<header id="masthead" class="siteHeader_siteHeader__XT02b">
	<div>
		<div class="siteHeader_inners__0p_FD"  #headerInners>
			<div class="siteHeader_secondary__h1g54">
				<div class="siteHeader_wrapper__uotep">
					<div class="siteHeader_navSecondary__l6VJN">
						<nav class="siteNavigation_siteNavigation__6KgTA siteNavigation_secondary__y7qzv"
							aria-label="Secondary Navigation">
							<ul>
								<li><a href="https://connect.ncoa.org/"
										class="siteNavigation_navLink__IGFpY">Events</a></li>
								<li><a href="/research" class="siteNavigation_navLink__IGFpY">Research</a></li>
								<li><a href="https://benefitscheckup.org/"
										class="siteNavigation_navLink__IGFpY">BenefitsCheckUp®</a></li>
							</ul>
						</nav>
						<button id="search-overlay-trigger" class="searchUtilities_searchOverlayTrigger__1tqxh"
							(click)="openSearch()" type="button" aria-controls="search-overlay"
							aria-label="Open Site Search" aria-expanded="false" aria-haspopup="dialog">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" aria-hidden="true"
								tab-index="-1" width="14" height="14">
								<path fill="#2C2C2C"
									d="M9.625 8.5h-.592l-.21-.203A4.85 4.85 0 0 0 10 5.125 4.875 4.875 0 1 0 5.125 10a4.85 4.85 0 0 0 3.172-1.178l.203.21v.593l3.75 3.742 1.117-1.117zm-4.5 0A3.37 3.37 0 0 1 1.75 5.125 3.37 3.37 0 0 1 5.125 1.75 3.37 3.37 0 0 1 8.5 5.125 3.37 3.37 0 0 1 5.125 8.5">
								</path>
							</svg>
							Search
						</button>
					</div>
				</div>
			</div>
			<div class="siteHeader_primary__TiJxZ">
				<div class="siteHeader_wrapper__uotep">
					<div class="siteHeader_logo__kHvn7"><a href="/"><img
								alt="National Council on Aging logo. We believe every person deserves to age well. That’s why we’re on a path to improve the lives of 40 million older adults by 2030."
								loading="lazy" width="0" height="0" decoding="async" data-nimg="1"
								style="color:transparent"
								src="https://assets-us-01.kc-usercontent.com/ffacfe7d-10b6-0083-2632-604077fd4eca/97550267-1d59-4894-ad15-802532256072/ncoa-logo-75th.png"></a>
					</div>
					<div class="siteHeader_mobileDonateCta__X_Oap">
						<a class="buttonCta_buttonSizeSmall__ApzzE buttonCta_buttonThemeGreen__gOx_M"
							href="https://act.ncoa.org/a/donate-1">Donate</a>
					</div>
					<div class="siteHeader_mobileMenuTrigger__BM7TX">
						<button id="mobile-menu-open-button" (click)="showMenu()"
							class="openButton_mobileOpenButton__C_BQI" type="button"
							aria-controls="mobile-menu-container" aria-label="Open Mobile Navigation"
							aria-expanded="false" aria-haspopup="dialog"><svg xmlns="http://www.w3.org/2000/svg"
								width="31" height="20" fill="none" aria-hidden="true" tab-index="-1">
								<path fill="currentColor"
									d="M2.107 0C1.22 0 .5.64.5 1.429s.72 1.428 1.607 1.428h26.786c.887 0 1.607-.64 1.607-1.428C30.5.639 29.78 0 28.893 0zm0 8.571C1.22 8.571.5 9.211.5 10s.72 1.429 1.607 1.429h26.786c.887 0 1.607-.64 1.607-1.429s-.72-1.429-1.607-1.429zm0 8.572c-.887 0-1.607.64-1.607 1.428 0 .79.72 1.429 1.607 1.429h26.786c.887 0 1.607-.64 1.607-1.429s-.72-1.428-1.607-1.428z">
								</path>
							</svg>Menu</button>
					</div>
					<div class="siteHeader_navGroup__Juujy">
						<div class="siteHeader_navPrimary__Ff03g">
							<nav class="siteNavigation_siteNavigation__6KgTA siteNavigation_primary__TV7ok"
								aria-label="Primary">
								<ul>
									<li>
										<div class="siteNavigation_dropdownContainer__tJXjv">
											<button
												aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1"
												(click)="open('dropdown-aging-well')" id="dropdown-aging-well-button"
												aria-controls="dropdown-aging-well" type="button">Aging Well</button>
											<div class="siteNavigation_dropdown__JbQcP siteNavigation_first__nxv3M siteNavigation_hasInfo__zJjC5"
												id="dropdown-aging-well">
												<div
													class="siteNavigation_navigation__5QBM_ siteNavigation_isTwoColumns__ZWzco">
													<ul class="siteNavigation_navList__oz3sA">
														<li><a href="/caregivers/benefits/caregiver-support">Caregiver
																Support</a></li>
														<li><a
																href="/older-adults/health/physical-health/chronic-disease">Chronic
																Conditions</a></li>
														<li><a href="/older-adults/health/prevention/falls-prevention">Falls
																Prevention</a></li>
														<li><a href="/older-adults/health">Health &amp; Wellness</a>
														</li>
														<li><a href="/nutrition-exercise">Nutrition &amp; Exercise</a></li>
														<li><a href="/aging-in-place">Aging in Place</a></li>
														<li><a href="/benefits-assistance">Benefits &amp;
																Assistance</a></li>
														<li><a
																href="/older-adults/benefits/healthcare/medicare">Medicare</a>
														</li>
														<li><a href="/older-adults/money/management">Money
																Management</a></li>
														<li><a href="/older-adults/money/work-retirement">Work &amp;
																Retirement</a></li>
													</ul>
													<div class="siteNavigation_navFooter__HcKfl"><a href="/aging-well">Aging Well:
															See All Topics</a></div>
												</div>
												<div class="siteNavigation_info__XkYxY">
													<h3 class="siteNavigation_infoHeading___qH0I">Aging Well</h3>
													<p>For most older adults, good health and financial wellness ensures
														independence, security, and the ability to afford a longer life.
														Discover tools and resources to improve quality of life and tips
														to make the most of your money.</p>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div class="siteNavigation_dropdownContainer__tJXjv"><button
												aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1"
												(click)="open('dropdown-professional-resources')" id="dropdown-professional-resources-button"
												aria-controls="dropdown-professional-resources"
												type="button">Professional Resources</button>
											<div class="siteNavigation_dropdown__JbQcP siteNavigation_hasInfo__zJjC5"
												id="dropdown-professional-resources">
												<div class="siteNavigation_navigation__5QBM_">
													<ul class="siteNavigation_navList__oz3sA">
														<li><a href="/page/benefits-access">Benefits Access</a></li>
														<li><a href="/page/economic-wellbeing">Economic Well-Being</a></li>
														<li><a href="/page/falls-prevention">Falls
																Prevention</a></li>
														<li><a
																href="/page/healthy-aging-programs">Healthy
																Aging Programs</a></li>
														<li><a href="/page/senior-centers">Senior Centers</a>
														</li>
														<li><a href="/page/workforce-training">Workforce
																Training</a></li>
													</ul>
													<div class="siteNavigation_navFooter__HcKfl"><a
															href="/professional-resources">Professional Resources: See All Topics</a></div>
												</div>
												<div class="siteNavigation_info__XkYxY">
													<h3 class="siteNavigation_infoHeading___qH0I">Professional Resources
													</h3>
													<p>Improving the lives of older adults, especially those who are
														struggling, is not just your job–it’s at your core. Gain your
														inspiration here and find NCOA tools, tips, and resources to
														help improve your delivery and service.</p>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div class="siteNavigation_dropdownContainer__tJXjv"><button
												aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1"
												(click)="open('dropdown-about-us')" aria-controls="dropdown-about-us" id="dropdown-about-us-button"
												type="button">About Us</button>
											<div class="siteNavigation_dropdown__JbQcP siteNavigation_hasInfo__zJjC5"
												id="dropdown-about-us">
												<div class="siteNavigation_navigation__5QBM_">
													<ul class="siteNavigation_navList__oz3sA">
														<li><a href="/page/about-us">About NCOA</a></li>
														<li><a href="/page/impact-report">Impact Report</a></li>
														<li><a href="/page/our-promise">Our Promise</a></li>
														<li><a href="/page/finances">Financial Information</a></li>
														<li><a href="/page/public-policy-positions">Policy Positions</a>
														</li>
													</ul>
													<div class="siteNavigation_navFooter__HcKfl"><a
															href="/about-us">About Us</a></div>
												</div>
												<div class="siteNavigation_info__XkYxY">
													<h3 class="siteNavigation_infoHeading___qH0I">About NCOA</h3>
													<p>We believe every person deserves to age well, especially as we’re
														living longer lives. Learn more about the work we do, the people
														we serve, and why equitable aging matters now more than ever.
													</p>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div class="siteNavigation_dropdownContainer__tJXjv">
											<button aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1" id="dropdown-get-involved-button"
												(click)="open('dropdown-get-involved')"
												aria-controls="dropdown-get-involved" type="button">Get
												Involved</button>
											<div class="siteNavigation_dropdown__JbQcP siteNavigation_last__KY9lw siteNavigation_hasInfo__zJjC5"
												id="dropdown-get-involved">
												<div class="siteNavigation_navigation__5QBM_">
													<ul class="siteNavigation_navList__oz3sA">
														<li><a href="/page/ways-to-give">Ways to Give</a></li>
														<li><a href="/partnership">Partner with Us</a></li>
														<li><a href="/page/advocate">Advocate</a></li>
														<li><a href="https://connect.ncoa.org">Events &amp;
																Education</a></li>
														<li><a href="/ncoa-map">Community Partners</a></li>
													</ul>
													<div class="siteNavigation_navFooter__HcKfl"><a
															href="/page/get-involved">Get Involved</a></div>
												</div>
												<div class="siteNavigation_info__XkYxY">
													<h3 class="siteNavigation_infoHeading___qH0I">Help NCOA Make an
														Impact</h3>
													<p>Help us build a more just and caring society in which each of us,
														as we age, lives with dignity, purpose, and security. Explore
														how to support our mission, partner with us, and advocate on
														behalf of older adults.</p>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</nav>
							<div class="siteHeader_donateCta__NiAHg"><a
									class="buttonCta_buttonSizeMedium__QtIRK buttonCta_buttonThemeGreen__gOx_M"
									href="https://act.ncoa.org/a/donate-1">Donate</a></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="mobile-menu-container" class="mobileMenuContainer_container__Iu36g" role="dialog" aria-modal="true"
			aria-labelledby="menu-heading">
			<div>
				<h1 id="menu-heading" class="screen-reader-text">Menu</h1>
				<div class="mobileMenuInner_header__OCcrM">
					<div class="mobileMenuInner_logo__mEG4K"><a href="/"><img
								alt="National Council on Aging logo. We believe every person deserves to age well. That’s why we’re on a path to improve the lives of 40 million older adults by 2030."
								loading="lazy" width="0" height="0" decoding="async" data-nimg="1"
								style="color:transparent"
								src="https://assets-us-01.kc-usercontent.com/ffacfe7d-10b6-0083-2632-604077fd4eca/97550267-1d59-4894-ad15-802532256072/ncoa-logo-75th.png"></a>
					</div>
					<div class="mobileMenuInner_action__i6T4S">
						<div class="mobileMenuInner_donateCta__7S0mj"><a
								class="buttonCta_buttonSizeSmall__ApzzE buttonCta_buttonThemeGreen__gOx_M"
								href="https://act.ncoa.org/a/donate-1">Donate</a></div><button
							class="closeButton_mobileCloseButton__t_HsR" type="button"
							aria-label="Close Mobile Navigation" aria-controls="mobile-menu-container"
							(click)="hideMenu()" aria-expanded="false"><svg xmlns="http://www.w3.org/2000/svg"
								width="22" height="22" fill="none" aria-hidden="true" tab-index="-1">
								<path fill="currentColor"
									d="M20.892 3.324a1.567 1.567 0 1 0-2.216-2.216L11 8.784 3.324 1.108a1.567 1.567 0 1 0-2.216 2.216L8.784 11l-7.676 7.676a1.567 1.567 0 1 0 2.216 2.216L11 13.216l7.676 7.676a1.567 1.567 0 1 0 2.216-2.216L13.216 11z">
								</path>
							</svg>Close</button>
					</div>
				</div>
				<div class="mobileMenuInner_search___hGrV">
					<div class="searchUtilities_searchMobile__CG61O">
						<form class="searchUtilities_searchForm__hWY6j" (ngSubmit)="onSubmit(mobileSearch)" #mobileSearch="ngForm">
							<svg
								xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"
								class="searchUtilities_searchIcon__HRnJP" aria-hidden="true" tab-index="-1" width="17"
								height="17">
								<path fill="#2C2C2C"
									d="M9.625 8.5h-.592l-.21-.203A4.85 4.85 0 0 0 10 5.125 4.875 4.875 0 1 0 5.125 10a4.85 4.85 0 0 0 3.172-1.178l.203.21v.593l3.75 3.742 1.117-1.117zm-4.5 0A3.37 3.37 0 0 1 1.75 5.125 3.37 3.37 0 0 1 5.125 1.75 3.37 3.37 0 0 1 8.5 5.125 3.37 3.37 0 0 1 5.125 8.5">
								</path>
							</svg>
							<label for="site-search-mobile"><span class="screen-reader-text">Search for a
									keyword</span>
									<input class="searchUtilities_searchInput__FbGS9" type="text" type="text" name="mobileSearchQuery" ngModel required
									id="site-search-mobile" placeholder="Search" name="search">
								</label></form>
					</div>
				</div>
				<div class="mobileMenuInner_navGroup__gOpNy">
					<div class="mobileMenuInner_navPrimary__ntGol">
						<nav class="siteNavigation_siteNavigation__6KgTA siteNavigation_primary__TV7ok"
							aria-label="Primary">
							<ul>
								<li>
									<div class="accordion_accordion__nMsqh">
										<div class="toggle_container__ZNCKu" id="mobile-menu-aging-well-container">
											<div class="toggle_toggleButton__Ix9nG"><button aria-expanded="false"
													aria-controls="toggle-content-mobile-menu-aging-well"
													id="toggle-button-mobile-menu-aging-well" type="button"
													(click)="openMobileMenu('mobile-menu-aging-well-container')"
													class="toggle-button"><span
														class="accordion_accordionButton__ew893">Aging
														Well</span></button></div>
											<div aria-labelledby="toggle-button-mobile-menu-aging-well"
												class="toggle-content" id="toggle-content-mobile-menu-aging-well"
												role="region">
												<div class="">
													<ul class="accordion_accordionList__PDpua">
														<li><a href="/caregivers/benefits/caregiver-support">Caregiver
																Support</a></li>
														<li><a
																href="/older-adults/health/physical-health/chronic-disease">Chronic
																Conditions</a></li>
														<li><a href="/older-adults/health/prevention/falls-prevention">Falls
																Prevention</a></li>
														<li><a href="/older-adults/health">Health &amp; Wellness</a>
														</li>
														<li><a href="/nutrition-exercise">Nutrition &amp; Exercise</a></li>
														<li><a href="/aging-in-place">Aging in Place</a></li>
														<li><a href="/benefits-assistance">Benefits &amp;
																Assistance</a></li>
														<li><a
																href="/older-adults/benefits/healthcare/medicare">Medicare</a>
														</li>
														<li><a href="/older-adults/money/management">Money
																Management</a></li>
														<li><a href="/older-adults/money/work-retirement">Work &amp;
																Retirement</a></li>
													</ul>
													<div class="accordion_accordionFooter__NNWOq"><a href="/aging-well">Aging
															Well: See All Topics</a></div>
												</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="accordion_accordion__nMsqh">
										<div class="toggle_container__ZNCKu" id="mobile-menu-professional-resources">
											<div class="toggle_toggleButton__Ix9nG"><button aria-expanded="false"
													aria-controls="toggle-content-mobile-menu-professional-resources"
													id="toggle-button-mobile-menu-professional-resources" type="button"
													(click)="openMobileMenu('mobile-menu-professional-resources')"
													class="toggle-button"><span
														class="accordion_accordionButton__ew893">Professional
														Resources</span></button></div>
											<div aria-labelledby="toggle-button-mobile-menu-professional-resources"
												class="toggle-content"
												id="toggle-content-mobile-menu-professional-resources" role="region">
												<div class="">
													<ul class="accordion_accordionList__PDpua">
														<li><a href="/page/benefits-access">Benefits Access</a></li>
														<li><a href="/page/economic-wellbeing">Economic Well-Being</a></li>
														<li><a href="/page/falls-prevention">Falls
																Prevention</a></li>
														<li><a
																href="/page/healthy-aging-programs">Healthy
																Aging Programs</a></li>
														<li><a href="/page/senior-centers">Senior Centers</a>
														</li>
														<li><a href="/page/direct-care-workforce-strategies-center">Workforce
																Training</a></li>
													</ul>
													<div class="accordion_accordionFooter__NNWOq"><a
															href="/professional-resources">Professional Resources: See All Topics</a></div>
												</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="accordion_accordion__nMsqh">
										<div class="toggle_container__ZNCKu" id="mobile-menu-about-us">
											<div class="toggle_toggleButton__Ix9nG"><button aria-expanded="false"
													aria-controls="toggle-content-mobile-menu-about-us"
													(click)="openMobileMenu('mobile-menu-about-us')"
													id="toggle-button-mobile-menu-about-us" type="button"
													class="toggle-button"><span
														class="accordion_accordionButton__ew893">About
														Us</span></button></div>
											<div aria-labelledby="toggle-button-mobile-menu-about-us"
												class="toggle-content" id="toggle-content-mobile-menu-about-us"
												role="region">
												<div class="">
													<ul class="accordion_accordionList__PDpua">
														<li><a href="/page/about-us">About NCOA</a></li>
														<li><a href="/page/impact-report">Impact Report</a></li>
														<li><a href="/page/our-promise">Our Promise</a></li>
														<li><a href="/page/finances">Financial Information</a></li>
														<li><a href="/page/public-policy-positions">Policy Positions</a>
														</li>
													</ul>
													<div class="accordion_accordionFooter__NNWOq"><a
															href="/about-us">About Us</a></div>
												</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="accordion_accordion__nMsqh">
										<div class="toggle_container__ZNCKu" id="mobile-menu-get-involved">
											<div class="toggle_toggleButton__Ix9nG"><button aria-expanded="false"
													aria-controls="toggle-content-mobile-menu-get-involved"
													(click)="openMobileMenu('mobile-menu-get-involved')"
													id="toggle-button-mobile-menu-get-involved" type="button"
													class="toggle-button"><span
														class="accordion_accordionButton__ew893">Get
														Involved</span></button></div>
											<div aria-labelledby="toggle-button-mobile-menu-get-involved"
												class="toggle-content" id="toggle-content-mobile-menu-get-involved"
												role="region">
												<div class="">
													<ul class="accordion_accordionList__PDpua">
														<li><a href="/page/ways-to-give">Ways to Give</a></li>
														<li><a href="/our-sponsors">Partner with Us</a></li>
														<li><a href="/page/advocate">Advocate</a></li>
														<li><a href="https://connect.ncoa.org">Events &amp;
																Education</a></li>
														<li><a href="/ncoa-map">Community Partners</a></li>
													</ul>
													<div class="accordion_accordionFooter__NNWOq"><a
															href="/page/get-involved">Get Involved</a></div>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</nav>
					</div>
					<div class="mobileMenuInner_navSecondary__MwNdI">
						<nav class="siteNavigation_siteNavigation__6KgTA siteNavigation_secondary__y7qzv"
							aria-label="Secondary Navigation">
							<ul>
								<li><a href="https://connect.ncoa.org/"
										class="siteNavigation_navLink__IGFpY">Events</a></li>
								<li><a href="/research" class="siteNavigation_navLink__IGFpY">Research</a></li>
								<li><a href="https://benefitscheckup.org/"
										class="siteNavigation_navLink__IGFpY">BenefitsCheckUp®</a></li>
							</ul>
						</nav>
					</div>
				</div>
				<div class="mobileMenuInner_socialLinks__CtGgz">
					<nav class="socialNavigation_socialNavigation__HIRqU" aria-label="Social Networks">
						<h2 class="socialNavigation_heading__dlZCX">Find us on Social</h2>
						<ul>
							<li class="socialNavigation_facebook__Z53Ii"><a
									href="https://www.facebook.com/NCOAging/"><span
										class="screen-reader-text">Facebook</span></a></li>
							<li class="socialNavigation_instagram__qWEzQ"><a
									href="https://www.instagram.com/ncoaging/"><span
										class="screen-reader-text">Instagram</span></a></li>
							<li class="socialNavigation_linkedin__D9cWB"><a
									href="https://www.linkedin.com/company/national-council-on-aging/"><span
										class="screen-reader-text">Linkedin</span></a></li>
							<li class="socialNavigation_twitter__0yA3a"><a href="https://twitter.com/NCOAging"><span
										class="screen-reader-text">Twitter</span></a></li>
							<li class="socialNavigation_youtube__ogp98"><a
									href="https://www.youtube.com/user/ncoaging"><span
										class="screen-reader-text">YouTube</span></a></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
		<div class="stickySiteHeaderContainer_container__vigC6"  id="sticky-menu" #stickyMenu aria-hidden="true">
			<div class="stickySiteHeaderInner_container__X6rQ3">
				<div class="stickySiteHeaderInner_inners__v1fmz">
					<div class="stickySiteHeaderInner_logo__t8KvC"><a href="/"><img
								alt="National Council on Aging logo. We believe every person deserves to age well. That’s why we’re on a path to improve the lives of 40 million older adults by 2030."
								loading="lazy" width="0" height="0" decoding="async" data-nimg="1"
								style="color:transparent"
								src="https://assets-us-01.kc-usercontent.com:443/d082166c-90e6-006b-215e-d2e1bdb985c7/b7a7dc06-57a4-4a2b-a3fd-a3bec2dad665/NCOA%C2%AE_Main-Logo_evergreen-jet.svg"></a>
					</div>
					<div class="stickySiteHeaderInner_navPrimary__qycsc">
						<nav class="siteNavigation_siteNavigation__6KgTA siteNavigation_primary__TV7ok"
							aria-label="Primary">
							<ul>
								<li>
									<div class="siteNavigation_dropdownContainer__tJXjv siteNavigation_isSticky__XXLZT"> 
										<button aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1" id="sticky_dropdown-aging-well-button"
											aria-controls="dropdown-aging-well" type="button" (click)="open('sticky_dropdown-aging-well')" >Aging Well</button>
										<div class="siteNavigation_dropdown__JbQcP siteNavigation_first__nxv3M siteNavigation_hasInfo__zJjC5"
											id="sticky_dropdown-aging-well">
											<div
												class="siteNavigation_navigation__5QBM_ siteNavigation_isTwoColumns__ZWzco">
												<ul class="siteNavigation_navList__oz3sA">
													<li><a href="/caregivers/benefits/caregiver-support">Caregiver
															Support</a></li>
													<li><a href="/older-adults/health/physical-health/chronic-disease">Chronic
															Conditions</a></li>
													<li><a href="/older-adults/health/prevention/falls-prevention">Falls
															Prevention</a></li>
													<li><a href="/older-adults/health">Health &amp; Wellness</a></li>
													<li><a href="/nutrition-exercise">Nutrition &amp; Exercise</a></li>
													<li><a href="/aging-in-place">Aging in Place</a></li>
													<li><a href="/benefits-assistance">Benefits &amp; Assistance</a>
													</li>
													<li><a
															href="/older-adults/benefits/healthcare/medicare">Medicare</a>
													</li>
													<li><a href="/older-adults/money/management">Money Management</a>
													</li>
													<li><a href="/older-adults/money/work-retirement">Work &amp;
															Retirement</a></li>
												</ul>
												<div class="siteNavigation_navFooter__HcKfl"><a href="/aging-well">Aging Well: See
														All Topics</a></div>
											</div>
											<div class="siteNavigation_info__XkYxY">
												<h3 class="siteNavigation_infoHeading___qH0I">Aging Well</h3>
												<p>For most older adults, good health and financial wellness ensures
													independence, security, and the ability to afford a longer life.
													Discover tools and resources to improve quality of life and tips to
													make the most of your money.</p>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="siteNavigation_dropdownContainer__tJXjv siteNavigation_isSticky__XXLZT">
										<button aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1" (click)="open('sticky_dropdown-professional-resources')" id="sticky_dropdown-professional-resources-button"
											aria-controls="dropdown-professional-resources" type="button">Professional
											Resources</button>
										<div class="siteNavigation_dropdown__JbQcP siteNavigation_hasInfo__zJjC5"
											id="sticky_dropdown-professional-resources">
											<div class="siteNavigation_navigation__5QBM_">
												<ul class="siteNavigation_navList__oz3sA">
													<li><a href="/professionals/benefits">Benefits Access</a></li>
													<li><a href="/page/falls-prevention">Falls
															Prevention</a></li>
													<li><a href="/page/healthy-aging-programs">Healthy
															Aging Programs</a></li>
													<li><a href="/page/senior-centers">Senior Centers</a></li>
													<li><a href="/page/workforce-training">Workforce
															Training</a></li>
												</ul>
												<div class="siteNavigation_navFooter__HcKfl"><a href="/professional-resources">Professional
														Resources: See All Topics</a></div>
											</div>
											<div class="siteNavigation_info__XkYxY">
												<h3 class="siteNavigation_infoHeading___qH0I">Professional Resources
												</h3>
												<p>Improving the lives of older adults, especially those who are
													struggling, is not just your job–it’s at your core. Gain your
													inspiration here and find NCOA tools, tips, and resources to help
													improve your delivery and service.</p>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="siteNavigation_dropdownContainer__tJXjv siteNavigation_isSticky__XXLZT">
										<button aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1" (click)="open('sticky_dropdown-about-us')" id="sticky_dropdown-about-us-button"
											aria-controls="dropdown-about-us" type="button">About Us</button>
										<div class="siteNavigation_dropdown__JbQcP siteNavigation_hasInfo__zJjC5"
											id="sticky_dropdown-about-us">
											<div class="siteNavigation_navigation__5QBM_">
												<ul class="siteNavigation_navList__oz3sA">
													<li><a href="/page/about-us">About NCOA</a></li>
													<li><a href="/page/impact-report">Impact Report</a></li>
													<li><a href="/page/our-promise">Our Promise</a></li>
													<li><a href="/page/finances">Financial Information</a></li>
													<li><a href="/page/public-policy-positions">Policy Positions</a>
													</li>
												</ul>
												<div class="siteNavigation_navFooter__HcKfl"><a
														href="/about-us">About Us</a></div>
											</div>
											<div class="siteNavigation_info__XkYxY">
												<h3 class="siteNavigation_infoHeading___qH0I">About NCOA</h3>
												<p>We believe every person deserves to age well, especially as we’re
													living longer lives. Learn more about the work we do, the people we
													serve, and why equitable aging matters now more than ever.</p>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div class="siteNavigation_dropdownContainer__tJXjv siteNavigation_isSticky__XXLZT">
										<button aria-expanded="false" class="siteNavigation_dropdownButton__3_Qz1" (click)="open('sticky_dropdown-get-involved')" id="sticky_dropdown-get-involved-button"
											aria-controls="dropdown-get-involved" type="button">Get Involved</button>
										<div class="siteNavigation_dropdown__JbQcP siteNavigation_last__KY9lw siteNavigation_hasInfo__zJjC5"
											id="sticky_dropdown-get-involved">
											<div class="siteNavigation_navigation__5QBM_">
												<ul class="siteNavigation_navList__oz3sA">
													<li><a href="/page/ways-to-give">Ways to Give</a></li>
													<li><a href="/partnership">Partner with Us</a></li>
													<li><a href="/page/advocate">Advocate</a></li>
													<li><a href="https://connect.ncoa.org">Events &amp; Education</a>
													</li>
													<li><a href="/ncoa-map">Community Partners</a></li>
												</ul>
												<div class="siteNavigation_navFooter__HcKfl"><a href="/page/get-involved">Get
														Involved</a></div>
											</div>
											<div class="siteNavigation_info__XkYxY">
												<h3 class="siteNavigation_infoHeading___qH0I">Help NCOA Make an Impact
												</h3>
												<p>Help us build a more just and caring society in which each of us, as
													we age, lives with dignity, purpose, and security. Explore how to
													support our mission, partner with us, and advocate on behalf of
													older adults.</p>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</nav>
					</div>
					<div class="stickySiteHeaderInner_searchOverlayTrigger__tmMtH"><button
							id="sticky-search-overlay-trigger" class="searchUtilities_searchOverlayTrigger__1tqxh"
							type="button" aria-controls="search-overlay" aria-label="Open Site Search" (click)="openSearch()"
							aria-expanded="false" aria-haspopup="dialog"><svg xmlns="http://www.w3.org/2000/svg"
								fill="none" viewBox="0 0 14 14" aria-hidden="true" tab-index="-1" width="14"
								height="14">
								<path fill="#2C2C2C"
									d="M9.625 8.5h-.592l-.21-.203A4.85 4.85 0 0 0 10 5.125 4.875 4.875 0 1 0 5.125 10a4.85 4.85 0 0 0 3.172-1.178l.203.21v.593l3.75 3.742 1.117-1.117zm-4.5 0A3.37 3.37 0 0 1 1.75 5.125 3.37 3.37 0 0 1 5.125 1.75 3.37 3.37 0 0 1 8.5 5.125 3.37 3.37 0 0 1 5.125 8.5">
								</path>
							</svg>Search</button></div>
					<div class="stickySiteHeaderInner_donateCta__Ygsp7"><a
							class="buttonCta_buttonSizeMedium__QtIRK buttonCta_buttonThemeGreen__gOx_M"
							href="https://act.ncoa.org/a/donate-1">Donate</a></div>
					<div class="stickySiteHeaderInner_mobileMenuTrigger__H6RrI"><button
							id="sticky-mobile-menu-open-button" class="openButton_mobileOpenButton__C_BQI" type="button"
							aria-controls="mobile-menu-container" aria-label="Open Mobile Navigation" (click)="showMenu()"
							aria-expanded="false" aria-haspopup="dialog"><svg xmlns="http://www.w3.org/2000/svg"
								width="31" height="20" fill="none" aria-hidden="true" tab-index="-1">
								<path fill="currentColor"
									d="M2.107 0C1.22 0 .5.64.5 1.429s.72 1.428 1.607 1.428h26.786c.887 0 1.607-.64 1.607-1.428C30.5.639 29.78 0 28.893 0zm0 8.571C1.22 8.571.5 9.211.5 10s.72 1.429 1.607 1.429h26.786c.887 0 1.607-.64 1.607-1.429s-.72-1.429-1.607-1.429zm0 8.572c-.887 0-1.607.64-1.607 1.428 0 .79.72 1.429 1.607 1.429h26.786c.887 0 1.607-.64 1.607-1.429s-.72-1.428-1.607-1.428z">
								</path>
							</svg>Menu</button></div>
				</div>
			</div>
		</div>
		<div id="search-overlay" class="searchUtilities_searchOverlay__MyNEJ" role="dialog" aria-modal="false"
			aria-labelledby="search-overlay-heading">
			<div class="searchUtilities_inners__PvzK_">
				<h2 id="search-overlay-heading" class="screen-reader-text">Search</h2>
				<form class="searchUtilities_searchForm__hWY6j" (ngSubmit)="onSubmit(searchForm)" #searchForm="ngForm">
					<label class="searchUtilities_searchInputLabel__Lci9W" for="search-overlay-input">
						<span class="screen-reader-text">Search for a keyword</span>
						<input class="searchUtilities_searchInput__FbGS9" type="text" id="search-overlay-input" type="text" name="searchQuery" ngModel required
							placeholder="ex. Prescription Assistance" name="search">
					</label>
					<input class="searchUtilities_searchSubmit__Zz_ld" type="submit" value="Search">
				</form>
				<div>
					<button class="searchUtilities_searchCloseButton__TgeFK" type="button" (click)="closeSearch()"
						aria-label="Close Site Search" aria-controls="search-overlay" aria-expanded="false"><svg
							xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none"
							class="searchUtilities_searchCloseIcon__70RjW">
							<path fill="currentColor"
								d="M20.892 3.324a1.567 1.567 0 1 0-2.216-2.216L11 8.784 3.324 1.108a1.567 1.567 0 1 0-2.216 2.216L8.784 11l-7.676 7.676a1.567 1.567 0 1 0 2.216 2.216L11 13.216l7.676 7.676a1.567 1.567 0 1 0 2.216-2.216L13.216 11z">
							</path>
						</svg>Close</button></div>
			</div>
		</div>
	</div>
</header>
<main class="main" id="main" tabindex="-1">
	<ng-content></ng-content>
</main>

<footer id="footer">
	<h2 class="screen-reader-text">Footer</h2>
	<div class="siteFooter_footer__hqi9g">
		<div class="siteFooter_wrapper__8J2vu">
			<div class="siteFooter_header__5Kb2c">
				<div class="siteFooter_footerLogo__q7vPZ"><a href="/"><img
							alt="National Council on Aging logo. We believe every person deserves to age well. That’s why we’re on a path to improve the lives of 40 million older adults by 2030."
							loading="lazy" width="0" height="0" decoding="async" data-nimg="1" style="color:transparent"
							src="https://assets-us-01.kc-usercontent.com:443/d082166c-90e6-006b-215e-d2e1bdb985c7/b7a7dc06-57a4-4a2b-a3fd-a3bec2dad665/NCOA%C2%AE_Main-Logo_evergreen-jet.svg"></a>
				</div>
				<div class="siteFooter_donateCta__d194e"><a
						class="buttonCta_buttonSizeMedium__QtIRK buttonCta_buttonThemeGreen__gOx_M"
						href="https://act.ncoa.org/a/donate-1">Donate</a></div>
			</div>
			<div class="siteFooter_navigation__5tvjL">
				<div class="siteFooter_menus__JT1M8">
					<nav aria-labelledby="footer-menu-about-us" class="siteFooter_nav__yt8zK">
						<h3 id="footer-menu-about-us" class="siteFooter_menuHeading__s8iHn">About Us</h3>
						<ul aria-labelledby="footer-menu-about-us" role="list">
							<li><a href="/page/about-us">About NCOA</a></li>
							<li><a href="/page/impact-report">Impact Report</a></li>
							<li><a href="/page/our-promise">Our Promise</a></li>
							<li><a href="/page/finances">Financial Information</a></li>
							<li><a href="/page/public-policy-positions">Policy Positions</a></li>
						</ul>
					</nav>
					<div class="siteFooter_toggle__ga6ux">
						<div class="toggle_container__ZNCKu" id="footer-menu-about-us-container">
							<div class="toggle_toggleButton__Ix9nG"><button aria-expanded="false"
									aria-controls="toggle-content-footer-menu-about-us"
									(click)="openMobileMenu('footer-menu-about-us-container')"
									id="toggle-button-footer-menu-about-us" type="button" class="toggle-button">
									<h3 id="footer-menu-about-us" class="siteFooter_menuHeading__s8iHn">About Us</h3>
								</button></div>
							<div aria-labelledby="toggle-button-footer-menu-about-us" class="toggle-content"
								id="toggle-content-footer-menu-about-us" role="region">
								<ul aria-labelledby="footer-menu-about-us" role="list">
									<li><a href="/page/about-us">About NCOA</a></li>
									<li><a href="/page/impact-report">Impact Report</a></li>
									<li><a href="/page/our-promise">Our Promise</a></li>
									<li><a href="/page/finances">Financial Information</a></li>
									<li><a href="/page/public-policy-positions">Policy Positions</a></li>
								</ul>
							</div>
						</div>
					</div>
					<nav aria-labelledby="footer-menu-quick-links" class="siteFooter_nav__yt8zK">
						<h3 id="footer-menu-quick-links" class="siteFooter_menuHeading__s8iHn">Quick Links</h3>
						<ul aria-labelledby="footer-menu-quick-links" role="list">
							<li><a href="/page/press-room">Press Room</a></li>
							<li><a href="/page/action-center">Action Center</a></li>
							<li><a href="/page/careers">Careers</a></li>
							<li><a href="/page/contact-us">Contact Us</a></li>
						</ul>
					</nav>
					<div class="siteFooter_toggle__ga6ux">
						<div class="toggle_container__ZNCKu" id="footer-menu-quick-links-container">
							<div class="toggle_toggleButton__Ix9nG"><button aria-expanded="false"
									aria-controls="toggle-content-footer-menu-quick-links"
									(click)="openMobileMenu('footer-menu-quick-links-container')"
									id="toggle-button-footer-menu-quick-links" type="button" class="toggle-button">
									<h3 id="footer-menu-quick-links" class="siteFooter_menuHeading__s8iHn">Quick Links
									</h3>
								</button></div>
							<div aria-labelledby="toggle-button-footer-menu-quick-links" class="toggle-content"
								id="toggle-content-footer-menu-quick-links" role="region">
								<ul aria-labelledby="footer-menu-quick-links" role="list">
									<li><a href="/page/press-room">Press Room</a></li>
									<li><a href="/page/action-center">Action Center</a></li>
									<li><a href="/page/careers">Careers</a></li>
									<li><a href="/page/contact-us">Contact Us</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="siteFooter_social__ZMiE1">
					<div class="siteFooter_charityBadges__twC8d">
						<ul>
							<li><a
									href="https://give.org/charity-reviews/national/Elderly/National-Council-on-Aging-in-Arlington-va-3133">
									<figure class="imageWrapper_imageWrapper__eiLsy image-wrapper"><img
											alt="BBB Accredited Charity, give.org" loading="lazy" width="44" height="75"
											decoding="async" data-nimg="1" class="image-wrapper-image"
											style="color:transparent; width: 44px; height: 75px;"
											src="/assets/images/bbb.png"></figure>
								</a></li>
							<li><a href="https://www.guidestar.org/profile/13-1932384">
									<figure class="imageWrapper_imageWrapper__eiLsy image-wrapper"><img
											alt="Gold Transparency 2024, Candid" loading="lazy" width="73" height="73"
											decoding="async" data-nimg="1" class="image-wrapper-image"
											style="color:transparent; width: 73px; height: 73px;"
											src="/assets/images/candid-light.png"></figure>
								</a></li>
							<li><a href="https://www.charitynavigator.org/ein/131932384">
									<figure class="imageWrapper_imageWrapper__eiLsy image-wrapper"><img
											alt="Charity Navigator, Four-Star" loading="lazy" width="68" height="68"
											decoding="async" data-nimg="1" class="image-wrapper-image"
											style="color:transparent;width: 68px; height: 68px;"
											src="assets/images/charity-navigator.svg"></figure>
								</a></li>
							<li><a href="https://www.charitywatch.org/charities/national-council-on-aging">
									<figure class="imageWrapper_imageWrapper__eiLsy image-wrapper"><img
											alt="Charity Watch, Top Rated" loading="lazy" width="81" height="62"
											decoding="async" data-nimg="1" class="image-wrapper-image"
											style="color:transparent; width: 81px; height: 62px;"
											src="assets/images/charity-watch.svg"></figure>
								</a></li>
						</ul>
					</div>
					<nav class="socialNavigation_socialNavigation__HIRqU socialNavigation_footer__c3XCJ"
						aria-label="Social Networks">
						<h3 class="socialNavigation_heading__dlZCX">Follow NCOA on Social Media:</h3>
						<ul>
							<li class="socialNavigation_facebook__Z53Ii"><a
									href="https://www.facebook.com/NCOAging/"><span
										class="screen-reader-text">Facebook</span></a></li>
							<li class="socialNavigation_linkedin__D9cWB"><a
									href="https://www.linkedin.com/company/national-council-on-aging/"><span
										class="screen-reader-text">Linkedin</span></a></li>
							<li class="socialNavigation_instagram__qWEzQ"><a
									href="https://www.instagram.com/ncoaging/"><span
										class="screen-reader-text">Instagram</span></a></li>
							<li class="socialNavigation_twitter__0yA3a"><a href="https://twitter.com/NCOAging"><span
										class="screen-reader-text">Twitter</span></a></li>
							<li class="socialNavigation_youtube__ogp98"><a
									href="https://www.youtube.com/user/ncoaging"><span
										class="screen-reader-text">YouTube</span></a></li>
						</ul>
					</nav>
					<div class="siteFooter_addressDesktop__t7MLO">251 18th Street South, Suite 500, Arlington, VA 22202
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="siteFooter_secondary__v50TV">
		<div class="siteFooter_secondaryWrapper__C_s_h">
			<div class="siteFooter_addressMobile__ad44S">251 18th Street South, Suite 500, Arlington, VA 22202</div>
			<p class="siteFooter_copyright__n4BLF">© {{currentYear}} National Council on Aging,
				Inc.</p>
			<nav class="siteFooter_secondaryNav__JtDrH" aria-label="Secondary Footer">
				<h3 id="footer-secondary-nav-heading" class="screen-reader-text">Secondary Footer Navigation</h3>
				<ul aria-labelledby="footer-secondary-nav-heading" role="list">
					<li><a href="/page/privacy-policy">Privacy Policy</a></li>
					<li><a href="/page/terms-of-service">Terms of Service</a></li>
					<li><a href="/page/accountability">Ethics and Compliance</a></li>
				</ul>
			</nav>
		</div>
	</div>
</footer>

<ncoa-cookies></ncoa-cookies>

<ncoa-modal-container></ncoa-modal-container>
<!-- <ncoa-modal *ngIf="!hasDisplayOnce" [id]="'general_modal'" [type]="'general'" [isOpen]="open" [heading]="heading"
  	  [description]="description" [cta]="cta" [backgroundImage]="backgroundImage">
	</ncoa-modal>
-->