import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { KontentDeliveryService } from './kontent-delivery.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  documentRoot: HTMLElement;
  documentBody: HTMLElement;
  currentURL: string = location ? location.href : '';
  isBrowser: boolean;
  footerData: any = null;
  navData: any = null;
  cookieData: any = null;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private kontentDeliveryService: KontentDeliveryService,
    private urlService: UrlService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.documentRoot = document.documentElement;
    this.documentBody = document.body;
  }

  makeAKebab(raw: string): string {
    return raw.replace(/([a-z])([A-Z])/g, '$1').replace(/[\s_]+/g, '-').toLowerCase();
  };

  async loadGlobalComponentsData() {
    await this.kontentDeliveryService.getItemAndCache(null, {
      'system.codename[in]': 'footer,primary_navigation,cookie_banner',
      'depth': 5,
    })
      .then((response: any) => {
        const footerData = response.items.find((item) => item.system.type === 'footer_navigation');
        const navData = response.items.find((item) => item.system.type === 'primary_navigation');
        const cookieData = response.items.find((item) => item.system.type === 'component___cookie_banner');
        const modularContent = response.modular_content;

        // footer data
        this.processFooterData(footerData, modularContent);

        // nav data
        this.processNavigationData(navData, modularContent);

        // cookie data
        this.processCookieData(cookieData, modularContent);
      });
  }

  loadFooter(flag: boolean) {
    if ( flag ) {
      document.querySelector<HTMLElement>('.global-footer')?.classList.add('is-loaded');
    }
  }

  private generateURL(page, modularContent): string {
    switch (page.system.type) {
      case 'ncoa_article_content': {
        return `/article/${page.elements.url.value}`;
      }
      case 'standard_page__special': {
        return `/page/${page.elements.url.value}`;
      }
      case 'template___audience_page': {
        const audience = page.elements.audiences.value[0].codename;
        return `/${audience.replace(/_/g, '-')}`;
      }
      case 'taxonomy_custom_content': {
        return `/${page.elements.category_page_url.value}`;
      }
      case 'standard_page': {
        const pageURL = page.elements.url.value;

        const parentPageCodeName = page.elements.parent_page.value;

        if (parentPageCodeName) {
          const parentPage = modularContent[parentPageCodeName];

          if (parentPage) {
            return `/page/${parentPage.elements.url.value}/${pageURL}`;
          }
        }

        return `/page/${pageURL}`;
      }
      case 'template___other_taxonomy': {
        const slug = page.system.name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^\w\-]+/g, '')
          .replace(/\-\-+/g, '-')
          .replace(/^-+/, '')
          .replace(/-+$/, '');

        return `/${slug}`;
      }
    }
  }

  processFooterData(data, modularContent) {
    // primary cta
    const primaryCTACodename = data.elements.primary_cta.value[0];
    const primaryCTA = modularContent[primaryCTACodename];
    const primaryCTAText = primaryCTA.elements.link_name.value;
    let primaryCTAUrl = primaryCTA.elements.link_url.value;

    if (primaryCTA.elements.linked_content.value.length > 0) {
      const codename = primaryCTA.elements.linked_content.value[0];
      const page = modularContent[codename];

      primaryCTAUrl = this.generateURL(page, modularContent);
    }

    // primary links
    let primaryLinks = data.elements.footer_primary_links.value.map(codename => modularContent[codename]);
    primaryLinks = primaryLinks.map((link) => {
      const text = link.elements.link_name.value;
      let url = link.elements.link_url.value;

      if (link.elements.linked_content.value.length > 0) {
        const codename = link.elements.linked_content.value[0];
        const page = modularContent[codename];

        url = this.generateURL(page, modularContent);
      }

      return {
        text,
        url,
      };
    });

    // social links
    const socialTitle = data.elements.section_title.value;
    let socialLinks = data.elements.footer_social_links.value.map(codename => modularContent[codename]);
    socialLinks = socialLinks.map((link) => {
      const icon = link.elements.item_class.value;
      const url = link.elements.link_url.value;

      return {
        icon,
        url,
      };
    });

    // secondary links
    let secondaryLinks = data.elements.footer_secondary_links.value.map(codename => modularContent[codename]);
    secondaryLinks = secondaryLinks.map((link) => {
      const text = link.elements.link_name.value;
      let url = link.elements.link_url.value;

      if (link.elements.linked_content.value.length > 0) {
        const codename = link.elements.linked_content.value[0];
        const page = modularContent[codename];

        url = this.generateURL(page, modularContent);
      }

      return {
        text,
        url,
      };
    });

    // copyright
    const copyright = data.elements.copyright_information.value;

    const footerLogo = data.elements?.footer_logo?.value?.[0]?.url ?? '../../assets/images/logo-2021-color.svg';
    this.footerData = {
      logo: { url: footerLogo },
      buttonLink: {
        text: primaryCTAText,
        url: primaryCTAUrl,
      },
      links: primaryLinks,
      socialTitle,
      socialLinks,
      policyLinks: secondaryLinks,
      copyright,
    };
  }

  processNavigationData(data, modularContent) {
    // logo
    const kenticoLogo = data.elements?.main_logo_image?.value[0] ?? null;

    const logo = {
      url: kenticoLogo?.url.length? kenticoLogo.url :  '../../assets/images/logo-2021-color.svg',
      url2: 'https://angular.ncoa.org/assets/images/logo-2021-white.svg',
      alt:'NCOA Logo',
    };

    const favicon = data?.elements?.favicon?.value[0]?.url ?? null;


    // donate link
    const donateLink = modularContent[data.elements.donate_page.value[0]];
    let donateLinkURL = donateLink.elements.link_url.value;

    if (donateLink.elements.linked_content.value.length > 0) {
      const page = modularContent[donateLink.elements.linked_content.value[0]];
      donateLinkURL = this.generateURL(page, modularContent);
    }

    // primary links
    let primaryLinks = data.elements.primary_navigation_primary_links.value.map(codename => modularContent[codename]);
    primaryLinks = primaryLinks.map((link) => {
      if(link.system.type !== 'navigation_item_0ae4ab8') return;
      const text = link.elements.link_name.value;
      let url = link.elements.link_url.value;

      if (link.elements.linked_content.value.length > 0) {
        const page = modularContent[link.elements.linked_content.value[0]];
        url = this.generateURL(page, modularContent);
      }

      let subLinks = link.elements.sub_navigation_item?.value.map(codename => modularContent[codename]);
      subLinks = subLinks.map((link) => {
        if(link.system.type !== 'navigation_item_0ae4ab8') return;
        const text = link.elements?.link_name?.value;
        let url = link.elements?.link_url?.value;

        if (link.elements.linked_content.value.length > 0) {
          const page = modularContent[link.elements.linked_content.value[0]];
          url = this.generateURL(page, modularContent);
        }

        return {
          text,
          url,
        };
      });

      return {
        text,
        url,
        sub: subLinks,
      }
    });

    // secondary links
    let secondaryLinks = data.elements.primary_navigation_secondary_links.value.map(codename => modularContent[codename]);
    secondaryLinks = secondaryLinks.map(link => {
      const text = link.elements.link_name.value;
      let url = link.elements.link_url.value;

      if (link.elements.linked_content.value.length > 0) {
        const page = modularContent[link.elements.linked_content.value[0]];
        url = this.generateURL(page, modularContent);
      }

      return {
        text,
        url,
      };
    });

    // social links
    let socialLinks = data.elements.primary_navigation_social_links.value.map(codename => modularContent[codename]);
    socialLinks = socialLinks.map(link => {
      const account = link.elements.item_class.value;
      const url = link.elements.link_url.value;

      return {
        account,
        url,
      };
    });

    this.navData = {
      logo,
      donate: {
        text: donateLink.elements.link_name.value,
        url: donateLinkURL,
        class: donateLink.elements.item_class.value
      },
      links: primaryLinks,
      menu: secondaryLinks,
      socials: socialLinks,
      favicon,
    };
  }

  processCookieData(data, modularContent) {
    const text = data.elements.privacy_policy_url_text.value;
    const page = modularContent[data.elements.privacy_policy_url.value[0]];
    const url = this.generateURL(page, modularContent);

    this.cookieData = {
      text,
      url,
    };
  }

  saveCurrentURL(url) {
    this.currentURL = url;
  }

  getCurrentURL() {
    return this.currentURL;
  }

  sendNewsletter(url, callback) {
    const s = document.createElement('script');

    s.src = url;
    s.onload = () => {
      callback();
      document.body.removeChild(s);
    };
    document.body.appendChild(s);
  }

  remodelImageSrc(url, component = '') {

    //set default breakpoint props and object
    const dpr = 2;
    const fm = 'pjpg';
    const q = 60;
    const dimensions = {
      mobile: 486,
      tablet: 1024,
      desktop: 1440
    }

    const mediaQueries = {
      mobile: 'all and (max-width: 767px)',
      tablet: '(min-width: 768px) and (max-width: 1239px)',
      desktop: 'all and (min-width: 1240px)',
    }

    let breakpointProps = {
      mobile: {
        dpr: dpr,
        width: dimensions.mobile,
        media: mediaQueries.mobile,
        fm: fm,
        q: q
      },
      tablet: {
        dpr: dpr,
        width: dimensions.tablet,
        media: mediaQueries.tablet,
        fm: fm,
        q: q
      },
      desktop: {
        dpr: dpr,
        width: dimensions.desktop,
        media: mediaQueries.desktop,
        fm: fm,
        q: q
      },
    }

    switch(component) {
      case 'callToDonate':
      case 'featuredPageBlock':
      case 'featuredToolBreaker':
      case 'moreStories':
      case 'benefits':
      case 'promoBackground':
        breakpointProps.tablet.width = 800;
        breakpointProps.desktop.width = 800;
        break;

      case 'homeHero':
      case 'mission':
      case 'categoryHero':
        breakpointProps.tablet.width = 700;
        breakpointProps.desktop.width = 700;
        break;

      case 'relatedArticles':
        breakpointProps.tablet.width = 460;
        breakpointProps.desktop.width = 460;
        break;
    }

    return [
      {
        url: `${url}?w=${breakpointProps.mobile.width}&dpr=${breakpointProps.mobile.dpr}&fm=${breakpointProps.mobile.fm}&q=${breakpointProps.mobile.q}`,
        media: breakpointProps.mobile.media,
      },
      {
        url: `${url}?w=${breakpointProps.tablet.width}&dpr=${breakpointProps.tablet.dpr}&fm=${breakpointProps.tablet.fm}&q=${breakpointProps.tablet.q}`,
        media: breakpointProps.tablet.media,
      },
      {
        url: `${url}?w=${breakpointProps.desktop.width}&dpr=${breakpointProps.desktop.dpr}&fm=${breakpointProps.desktop.fm}&q=${breakpointProps.desktop.q}`,
        media: breakpointProps.desktop.media,
      },
    ]
  }
}
